.article {
  color: #122D3A;
  max-width: 700px;
  width: 50vw;
  line-height: 1.5;
  margin-bottom: 50px;

  h1 {
    font-size: 42px;
    @media screen and (max-width: 720px) {
      font-size: 24px;
    }
  }

  @media screen and (max-width: 720px) {
    width: 100%;
    font-size: 12px;
  }

}