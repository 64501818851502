.email {
  color: #122D3A;
  font-size: 2.916666667vw;
  font-weight: 500;

  &:hover {
    text-decoration: none;
  }

  @media screen and (max-width: 720px) {
    font-size: 12px;
  }
}