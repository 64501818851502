.hero {
  width: 100%;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.heading {
  color: #122D3A;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 2.916666667vw;

  @media screen and (max-width: 720px) {
    font-size: 28px;
  }
}

.body {
  font-size: 1.25vw;
  max-width: 36.80555556vw;
  line-height: 2.083333333vw;

  @media screen and (max-width: 720px) {
    font-size: 12px;
    line-height: 1.6;
    max-width: 100%;
  }
}