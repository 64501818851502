.navigation {
  padding: 50px 0;

  @media screen and (max-width: 720px) {
    padding: 25px 0;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }

  li {
    padding: 0 40px;
    @media screen and (max-width: 720px) {
      padding: 0 15px;
    }
  }
}

.nav-item {
  color: #000;
  text-decoration: none;
  font-size: 1.111111111vw;

  @media screen and (max-width: 720px) {
    font-size: 14px;
  }
}