.App-logo {
  width: 15vw;
  min-width: 216px;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}