.page {

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 40px;
  min-width: 100vw;
  min-height: 100vh;
  justify-content: space-between;
  transition: background-color .5s ease-in-out;

  @media screen and (max-width: 720px) {
    padding: 25px;
  }
}

.copyright {
  color: rgba(18, 45, 58, 0.28);
}

.footer {
  justify-self: flex-end;
  margin-top: auto;
  position: relative;
  padding: 50px 0;
  text-align: center;

  @media screen and (max-width: 720px) {
    padding: 25px 0;
    font-size: 12px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 276px;
    height: 3px;
    transform: translateX(-50%);
    left: 50%;
    top: 0;
    background: #122D3A;
  }
}

.main {
  margin: auto 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}